import React, { useState } from 'react';
import styled from 'styled-components';
import { Route } from 'react-router-dom';
import SearchApartment from './search-apartment';
import Apartment from './apartment';
import clientFactory from './apollo-client';
import { GlobalStyles } from './global-styles';
import { Heading } from '../../ui/text/Heading';
import { Seo } from '../../../utils/seo/Index';

// export const StateContext = React.createContext(null);

const client = clientFactory();

export const RangeContext = React.createContext<any>(null);

const Verdi: React.FC = () => {
  const [range, setRange] = useState<any>(null);

  return (
    <>
      <Seo
        title="PrivatMegleren - Selge bolig - boligkalkulator"
        description="Boligkalkulatoren til PrivatMegleren vil gi deg en verdivurdering på din eiendom basert på tilgjengelig data."
        image="https://cdn.reeltime.no/pm_assets/img/bakgrunner/pmfront.jpg"
        imageTitle="/verdivurdering-boligkalkulator-PrivatMegleren"
        url=""
      />
      <div className="kampanjeside">
        <GlobalStyles />
        <Route
          exact
          /*  path="/:settprispaa(settprispa|settprispaa|settprispå|privatmeglerenverdi)" */
          path="/verdivurdering-boligkalkulator"
          render={() => {
            return (
              <>
                <Container bg="https://cdn.reeltime.no/pm_assets/stillinger/topp_bakgrunn.jpg">
                  <Wrapper>
                    <Title>Hva er boligen verdt?</Title>
                    <SettPrisPaa>
                      La oss gi deg et estimat på hva eiendommen eller
                      fritidseiendommen er verdt.
                    </SettPrisPaa>
                    <SettPrisPaa style={{ marginBottom: '30px' }}>
                      Skriv inn adressen, så vil vi basert på tilgjengelig data
                      gi deg et prisestimat. Ønsker du en nøyaktig prisvurdering
                      eller E-takst kan du bestille&nbsp;
                      <a href="https://privatmegleren.no/verdivurdering-selge-eiendom">
                        verdivurdering&nbsp;her.
                      </a>
                    </SettPrisPaa>
                    <SettPrisPaa style={{ marginBottom: '30px' }}>
                      <FakeLinkBecauseDesignersHateUsers>
                        Skriv inn adressen, så vil vi basert på tilgjengelige
                        data gi deg et prisestimat.
                      </FakeLinkBecauseDesignersHateUsers>
                    </SettPrisPaa>
                    <SearchApartment client={client} />
                    <SettPrisPaa style={{ marginBottom: '30px' }}>
                      Forvent nyttige løsninger.{' '}
                      <FakeLinkBecauseDesignersHateUsers>
                        Forvent mer.
                      </FakeLinkBecauseDesignersHateUsers>
                    </SettPrisPaa>

                    <Bar />
                    <TextAnimnationSection>
                      <TextAnimation />
                    </TextAnimnationSection>
                  </Wrapper>
                </Container>
                <HeaderTopOverlay />
              </>
            );
          }}
        />
        <Route
          exact
          path="/verdivurdering-boligkalkulator/apartment/:id"
          render={({ match, history }) => {
            return (
              <RangeContext.Provider value={{ range, setRange }}>
                <HeightContainer>
                  <Apartment client={client} match={match} history={history} />
                </HeightContainer>
              </RangeContext.Provider>
            );
          }}
        />
      </div>
    </>
  );
};

export default Verdi;

const HeightContainer = styled.div`
  min-height: 800px;
`;

const SettPrisPaa = styled.p`
  text-align: center;
  margin: 2em 0 0.4em;

  a {
    color: ${({ theme }) => theme.colors.coral};
    text-decoration: none;
  }
`;

const Container = styled.div<{ bg: string }>`
  padding: 180px 18px 180px 18px;
  max-width: 600px;
  width: 100%;
  margin: 0 auto;
  align-self: center;

  
    &:after {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      content: '';
      background-image: url(${(props) => (props.bg ? props.bg : 'none')});
      background-size: cover;
      background-position: center -26px;
      background-attachment: fixed;
      background-repeat: no-repeat;
      opacity: 0.7;
      z-index: 0;
      @media (min-width: 600px) {
        background-position: center -26px;
      }
    }
  }
`;

const Wrapper = styled.div`
  position: relative;
  z-index: 1;
`;
const TextAnimnationSection = styled.div`
  position: relative;
  width: 100%;
`;

const TextAnimation = styled.div`
  position: absolute;
  top: -15px;
  left: 0;
  right: 0;
  width: 100%;
  height: 100px;
  background: url('https://cdn.reeltime.no/pm_assets/stillinger/PMsppTextanim.gif')
    no-repeat;
  background-size: contain;
  background-position: center;
  z-index: -1;
`;

const Title = styled.h1`
  text-align: center;
`;

const Bar = styled.div`
  margin: 40px auto;
  max-width: 200px;
  width: 100%;
  height: 2px;
  background: #e8c893;
`;

const FakeLinkBecauseDesignersHateUsers = styled.span`
  color: #e8c893;
`;
const HeaderTopOverlay = styled.div`
  position: absolute;
  background-color: rgba(0, 0, 0, 0.6);
  content: '';
  width: 100%;
  height: 100%;
  top: 0;
`;
